import GuestLayout from '@/components/layouts/GuestLayout';
import styled from 'styled-components';
import Container from '../../components/Container';
import React, { useCallback } from 'react';
import { useLaravelReactI18n } from 'laravel-react-i18n';
import Processing from '../../components/Processing';
import LoginForm from '../../components/forms/LoginForm';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import Link from '../../components/Link';
import Box from '../../components/Box';
import { useForm } from 'laravel-precognition-react-inertia';

const Login = () => {
    const translate = useLaravelReactI18n().t;
    const form = useForm('post', route('login'), {});

    const handleFormSubmit = useCallback(
        (event) => {
            event.preventDefault();
            form.submit();
        },
        [form],
    );

    return (
        <>
            <Container>
                <Component>
                    <Content>
                        <Processing loading={form.processing} />

                        <>
                            <Box as={'img'} src={'/assets/images/logos/primary/logo-small.png'} height={48} />

                            <LoginForm form={form} onSubmit={handleFormSubmit} mt={40} />

                            <Button className={'mt-6 bg-black'} onClick={handleFormSubmit}>
                                <span>{translate('pages.auth.login.login')}</span>
                                <ArrowUpRightIcon className={'w-6 aspect-square'} />
                            </Button>

                            <Typography mt={40}>
                                <Link href={route('register')}>{translate('pages.auth.login.noAccount')}</Link>
                            </Typography>
                        </>
                    </Content>
                </Component>
            </Container>
        </>
    );
};

const Component = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Content = styled.div`
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: 560px;
    background: ${({ theme }) => theme.colors.background.base};
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
`;

Login.layout = (children) => <GuestLayout children={children} />;

export default Login;
