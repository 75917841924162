import { Link } from '@inertiajs/react';
import TextField from '@/components/fields/TextField';
import { useLaravelReactI18n } from 'laravel-react-i18n';
import Box from '../Box';
import ErrorMessage from '@/components/ErrorMessage';
import React, { useCallback, useMemo } from 'react';
import Label from '@/components/Label';
import EmailField from '@/components/fields/EmailField';

const LoginForm = ({ form, ...properties }) => {
    const translate = useLaravelReactI18n().t;
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            form.setData((prevData) => ({ ...prevData, [name]: value }));
        },
        [form.setData],
    );

    return (
        <Box as={'form'} {...properties}>
            <div className={'flex flex-col gap-5'}>
                <div className={'flex flex-col gap-2'}>
                    <Label>{translate('general.email')}</Label>

                    <EmailField name={'email'} value={data?.email} autoComplete={'username'} onChange={handleFormChange} className={'mt-2'} />

                    <ErrorMessage message={errors?.email} />
                </div>

                <div className={'flex flex-col gap-2'}>
                    <div className={'flex items-center justify-between'}>
                        <Label>{translate('general.password')}</Label>

                        <Link href={route('password.email')} tabIndex={-1}>
                            <p className={'text-sm text-gray-300'}>{translate('pages.auth.login.lostPassword')}</p>
                        </Link>
                    </div>

                    <TextField name={'password'} value={data?.password} autoComplete={'current-password'} type={'password'} onChange={handleFormChange} className={'mt-2'} />

                    <ErrorMessage message={errors?.password} />
                </div>
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default LoginForm;
